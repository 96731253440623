.sub-heading > h2 {
    text-align: center
}

.footer-logo > svg {
    version: 1;
    height: 40px;
}

#wp-emoji-styles-inline-css {
}

#wp-block-library-inline-css {
}

#global-styles-inline-css {
}

#twentytwentytwo-style-css {
}

#wp-fonts-local {
}

#preloader-logo {
    display: none;
}

#preloader-logo {
}

#svg-lang-ico {
}

#svg-user-ico {
}

#svg-setting-ico {
}

#svg-search-ico {
}

#svg-arrow-right-left-ico {
}

#svg-arrow-down-ico {
}

#svg-text-increase-ico {
}

#svg-text-decrease-ico {
}

#svg-file-ico {
}

#svg-text-normal-ico {
}

#svg-doc-check-ico {
}

#svg-doc-reload-ico {
}

#svg-doc-remove-ico {
}

#svg-doc-delete-ico {
}

#svg-doc-transfer-ico {
}

#svg-doc-edit-ico {
}

#svg-doc-stop-ico {
}

#svg-srv-1-ico {
}

#svg-srv-2-ico {
}

#svg-srv-3-ico {
}

#svg-srv-4-ico {
}

#svg-bell-ico {
}

#svg-boxs-ico {
}

#svg-logout-ico {
}

#svg-more-ico {
}

#svg-close-ico {
}

#svg-close-ico {
}

#svg-commercial-ico {
}

#svg-structural-ico {
}

#svg-next-ico {
}

#svg-shop-ico {
}

#svg-wagon-ico {
}

#svg-ice-cream-truck-ico {
}

#svg-food-truck-ico {
}

#svg-food-cart-ico {
}

#money-check-alt {
}

#svg-lang-ico {
}

#svg-user-ico {
}

#svg-setting-ico {
}

#svg-search-ico {
}

#svg-arrow-right-left-ico {
}

#svg-arrow-down-ico {
}

#svg-text-increase-ico {
}

#svg-text-decrease-ico {
}

#svg-text-normal-ico {
}

#svg-doc-check-ico {
}

#svg-doc-reload-ico {
}

#svg-doc-remove-ico {
}

#svg-doc-delete-ico {
}

#svg-doc-transfer-ico {
}

#svg-doc-edit-ico {
}

#svg-doc-stop-ico {
}

#svg-srv-1-ico {
}

#svg-srv-2-ico {
}

#svg-srv-3-ico {
}

#svg-srv-4-ico {
}

#svg-bell-ico {
}

#svg-boxs-ico {
}

#svg-logout-ico {
}

#svg-more-ico {
}

#svg-close-ico {
}

#svg-close-ico {
}

#svg-commercial-ico {
}

#svg-structural-ico {
}

#svg-next-ico {
}

#svg-shop-ico {
}

#svg-wagon-ico {
}

#svg-ice-cream-truck-ico {
}

#svg-food-truck-ico {
}

#svg-food-cart-ico {
}

#svg-filter-ico {
}

#svg-right-arrow-ico {
}

#svg-draft-ico {
}

#block-sitebranding {
}

#navbarSupportedContent {
}

#block-balady-main-menu {
}

#block-balady-main-menu-menu {
}

#search-modal {
}

#search-text {
}

.loader {
}

.loader-logo {
}

.loader-figure {
}

.svg-library.d-none {
}

.site-header-cont {
}

.site-header.inner-header-login {
}

.container-fluid {
}

.navbar.navbar-expand-lg {
}

.navbar-brand {
}

.logo {
    height: 40px;
}

.mainHeader {
}

.row {
}

.col.d-flex.columns {
}

.region.region--header {
}

.site-branding__logo {
}

.navbar.navbar-expand-lg.position-static.header-mobile-container {
}

.navbar-toggler {
}

.region.region--header-content {
}

.block-content {
}

.menu {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-1 {
}

.dropdown-toggle {
}

.dropdown-menu {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-1 {
}

.menu-item.menu-level-2.menu-count-1 {
}

.menu-item.menu-level-2.menu-count-2 {
}

.menu-item.menu-level-2.menu-count-3 {
}

.menu-item.menu-level-2.menu-count-4 {
}

.menu-item.menu-level-2.menu-count-5 {
}

.menu-item.menu-level-2.menu-count-6 {
}

.menu-item.menu-level-2.menu-count-7 {
}

.menu-item.menu-level-2.menu-count-8 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-2 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-3 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-4 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-2 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-3 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-5 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-6 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-7 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-8 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-9 {
}

.menu-item.menu-level-1.menu-count-10 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-4 {
}

.menu-item.menu-level-1.menu-count-5 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-5 {
}

.menu-item.menu-level-1.menu-count-1 {
}

.menu-item.menu-level-1.menu-count-2 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-6 {
}

.menu-item.menu-level-1.menu-count-3 {
}

.menu-item.menu-level-1.menu-count-4 {
}

.menu-item.menu-level-1.menu-count-6 {
}

.menu-item.menu-level-1.menu-count-7 {
}

.navbar-nav.nav-setting-user {
}

.nav-item.nav-item-notifications {
}

.nav-link {
}

.icon-back {
}

.modal-dialog.modal-lg {
}

.modal-content {
}

.modal-body {
}

.container {
}

.close {
}

.close-icon {
}

.col-12 {
}

.search-modal-form {
}

.input-wrap {
}

.small-p {
}

.search-submit {
}

.bg-grey {
}

.page-top-fixed {
}

.container.p-lg-0 {
}

.px-3.pb-2.px-md-0.d-flex.flex-column.flex-md-row.align-items-md-center.justify-content-between.page-btns-top-fixed {
}

.page-title-content {
}

.d-flex.justify-content-between {
}

.page-container {
}

.section.pt-0.mt-1.mt-md-4 {
}

.container.p-0 {
}

.card.p-lg-4 {
}

.card-body {
}

.col-md-12.center {
}

.sub-heading {
}

.col-md-12 {
    text-align: center;
}

.m-3 {
    margin: 20px
}

.col-md-6 {
}

.form-group.has-float-label {
}

.form-control {
}

.form-group.has-float-label.mb-4 {
}

.col-md-12.mt-4 {
}

.footer.footer-inner {
}

.footer-cont {
}

.side-one {
}

.footer-logo {
}

.copyright {
}

.side-two {
}

.list-unstyled {
}

.sub-heading > h2 {
    text-align: center
}

.footer-logo > svg {
    version: 1;
    height: 40px;
}

#wp-emoji-styles-inline-css {
}

#wp-block-library-inline-css {
}

#global-styles-inline-css {
}

#twentytwentytwo-style-css {
}

#wp-fonts-local {
}

#preloader-logo {
    display: none;
}

#preloader-logo {
}

#svg-lang-ico {
}

#svg-user-ico {
}

#svg-setting-ico {
}

#svg-search-ico {
}

#svg-arrow-right-left-ico {
}

#svg-arrow-down-ico {
}

#svg-text-increase-ico {
}

#svg-text-decrease-ico {
}

#svg-file-ico {
}

#svg-text-normal-ico {
}

#svg-doc-check-ico {
}

#svg-doc-reload-ico {
}

#svg-doc-remove-ico {
}

#svg-doc-delete-ico {
}

#svg-doc-transfer-ico {
}

#svg-doc-edit-ico {
}

#svg-doc-stop-ico {
}

#svg-srv-1-ico {
}

#svg-srv-2-ico {
}

#svg-srv-3-ico {
}

#svg-srv-4-ico {
}

#svg-bell-ico {
}

#svg-boxs-ico {
}

#svg-logout-ico {
}

#svg-more-ico {
}

#svg-close-ico {
}

#svg-close-ico {
}

#svg-commercial-ico {
}

#svg-structural-ico {
}

#svg-next-ico {
}

#svg-shop-ico {
}

#svg-wagon-ico {
}

#svg-ice-cream-truck-ico {
}

#svg-food-truck-ico {
}

#svg-food-cart-ico {
}

#money-check-alt {
}

#svg-lang-ico {
}

#svg-user-ico {
}

#svg-setting-ico {
}

#svg-search-ico {
}

#svg-arrow-right-left-ico {
}

#svg-arrow-down-ico {
}

#svg-text-increase-ico {
}

#svg-text-decrease-ico {
}

#svg-text-normal-ico {
}

#svg-doc-check-ico {
}

#svg-doc-reload-ico {
}

#svg-doc-remove-ico {
}

#svg-doc-delete-ico {
}

#svg-doc-transfer-ico {
}

#svg-doc-edit-ico {
}

#svg-doc-stop-ico {
}

#svg-srv-1-ico {
}

#svg-srv-2-ico {
}

#svg-srv-3-ico {
}

#svg-srv-4-ico {
}

#svg-bell-ico {
}

#svg-boxs-ico {
}

#svg-logout-ico {
}

#svg-more-ico {
}

#svg-close-ico {
}

#svg-close-ico {
}

#svg-commercial-ico {
}

#svg-structural-ico {
}

#svg-next-ico {
}

#svg-shop-ico {
}

#svg-wagon-ico {
}

#svg-ice-cream-truck-ico {
}

#svg-food-truck-ico {
}

#svg-food-cart-ico {
}

#svg-filter-ico {
}

#svg-right-arrow-ico {
}

#svg-draft-ico {
}

#block-sitebranding {
}

#navbarSupportedContent {
}

#block-balady-main-menu {
}

#block-balady-main-menu-menu {
}

#search-modal {
}

#search-text {
}

.loader {
}

.loader-logo {
}

.loader-figure {
}

.svg-library.d-none {
}

.site-header-cont {
}

.site-header.inner-header-login {
}

.container-fluid {
}

.navbar.navbar-expand-lg {
}

.navbar-brand {
}

.logo {
    height: 40px;
}

.mainHeader {
}

.row {
}

.col.d-flex.columns {
}

.region.region--header {
}

.site-branding__logo {
}

.navbar.navbar-expand-lg.position-static.header-mobile-container {
}

.navbar-toggler {
}

.region.region--header-content {
}

.block-content {
}

.menu {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-1 {
}

.dropdown-toggle {
}

.dropdown-menu {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-1 {
}

.menu-item.menu-level-2.menu-count-1 {
}

.menu-item.menu-level-2.menu-count-2 {
}

.menu-item.menu-level-2.menu-count-3 {
}

.menu-item.menu-level-2.menu-count-4 {
}

.menu-item.menu-level-2.menu-count-5 {
}

.menu-item.menu-level-2.menu-count-6 {
}

.menu-item.menu-level-2.menu-count-7 {
}

.menu-item.menu-level-2.menu-count-8 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-2 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-3 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-4 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-2 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-3 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-5 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-6 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-7 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-8 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-9 {
}

.menu-item.menu-level-1.menu-count-10 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-4 {
}

.menu-item.menu-level-1.menu-count-5 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-5 {
}

.menu-item.menu-level-1.menu-count-1 {
}

.menu-item.menu-level-1.menu-count-2 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-6 {
}

.menu-item.menu-level-1.menu-count-3 {
}

.menu-item.menu-level-1.menu-count-4 {
}

.menu-item.menu-level-1.menu-count-6 {
}

.menu-item.menu-level-1.menu-count-7 {
}

.navbar-nav.nav-setting-user {
}

.nav-item.nav-item-notifications {
}

.nav-link {
}

.icon-back {
}

.modal-dialog.modal-lg {
}

.modal-content {
}

.modal-body {
}

.container {
}

.close {
}

.close-icon {
}

.col-12 {
}

.search-modal-form {
}

.input-wrap {
}

.small-p {
}

.search-submit {
}

.bg-grey {
}

.page-top-fixed {
}

.container.p-lg-0 {
}

.px-3.pb-2.px-md-0.d-flex.flex-column.flex-md-row.align-items-md-center.justify-content-between.page-btns-top-fixed {
}

.page-title-content {
}

.d-flex.justify-content-between {
}

.page-container {
}

.section.pt-0.mt-1.mt-md-4 {
}

.container.p-0 {
}

.card.p-lg-4 {
}

.card-body {
}

.col-md-12.center {
}

.sub-heading {
}

.col-md-12 {
    text-align: center;
}

.m-3 {
    margin: 20px
}

.col-md-6 {
}

.form-group.has-float-label {
}

.form-control {
}

.form-group.has-float-label.mb-4 {
}

.col-md-12.mt-4 {
}

.footer.footer-inner {
}

.footer-cont {
}

.side-one {
}

.footer-logo {
}

.copyright {
}

.side-two {
}

.list-unstyled {
}

.sub-heading > h2 {
    text-align: center
}

.footer-logo > svg {
    version: 1;
    height: 40px;
}

#wp-emoji-styles-inline-css {
}

#wp-block-library-inline-css {
}

#global-styles-inline-css {
}

#twentytwentytwo-style-css {
}

#preloader-logo {
    display: none;
}

#preloader-logo {
}

#svg-lang-ico {
}

#svg-user-ico {
}

#svg-setting-ico {
}

#svg-search-ico {
}

#svg-arrow-right-left-ico {
}

#svg-arrow-down-ico {
}

#svg-text-increase-ico {
}

#svg-text-decrease-ico {
}

#svg-file-ico {
}

#svg-text-normal-ico {
}

#svg-doc-check-ico {
}

#svg-doc-reload-ico {
}

#svg-doc-remove-ico {
}

#svg-doc-delete-ico {
}

#svg-doc-transfer-ico {
}

#svg-doc-edit-ico {
}

#svg-doc-stop-ico {
}

#svg-srv-1-ico {
}

#svg-srv-2-ico {
}

#svg-srv-3-ico {
}

#svg-srv-4-ico {
}

#svg-bell-ico {
}

#svg-boxs-ico {
}

#svg-logout-ico {
}

#svg-more-ico {
}

#svg-close-ico {
}

#svg-close-ico {
}

#svg-commercial-ico {
}

#svg-structural-ico {
}

#svg-next-ico {
}

#svg-shop-ico {
}

#svg-wagon-ico {
}

#svg-ice-cream-truck-ico {
}

#svg-food-truck-ico {
}

#svg-food-cart-ico {
}

#money-check-alt {
}

#svg-lang-ico {
}

#svg-user-ico {
}

#svg-setting-ico {
}

#svg-search-ico {
}

#svg-arrow-right-left-ico {
}

#svg-arrow-down-ico {
}

#svg-text-increase-ico {
}

#svg-text-decrease-ico {
}

#svg-text-normal-ico {
}

#svg-doc-check-ico {
}

#svg-doc-reload-ico {
}

#svg-doc-remove-ico {
}

#svg-doc-delete-ico {
}

#svg-doc-transfer-ico {
}

#svg-doc-edit-ico {
}

#svg-doc-stop-ico {
}

#svg-srv-1-ico {
}

#svg-srv-2-ico {
}

#svg-srv-3-ico {
}

#svg-srv-4-ico {
}

#svg-bell-ico {
}

#svg-boxs-ico {
}

#svg-logout-ico {
}

#svg-more-ico {
}

#svg-close-ico {
}

#svg-close-ico {
}

#svg-commercial-ico {
}

#svg-structural-ico {
}

#svg-next-ico {
}

#svg-shop-ico {
}

#svg-wagon-ico {
}

#svg-ice-cream-truck-ico {
}

#svg-food-truck-ico {
}

#svg-food-cart-ico {
}

#svg-filter-ico {
}

#svg-right-arrow-ico {
}

#svg-draft-ico {
}

#block-sitebranding {
}

#navbarSupportedContent {
}

#block-balady-main-menu {
}

#block-balady-main-menu-menu {
}

#search-modal {
}

#search-text {
}

#wp-fonts-local {
}

.loader {
}

.loader-logo {
}

.loader-figure {
}

.svg-library.d-none {
}

.site-header-cont {
}

.site-header.inner-header-login {
}

.container-fluid {
}

.navbar.navbar-expand-lg {
}

.navbar-brand {
}

.logo {
    height: 40px;
}

.mainHeader {
}

.row {
}

.col.d-flex.columns {
}

.region.region--header {
}

.site-branding__logo {
}

.navbar.navbar-expand-lg.position-static.header-mobile-container {
}

.navbar-toggler {
}

.region.region--header-content {
}

.block-content {
}

.menu {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-1 {
}

.dropdown-toggle {
}

.dropdown-menu {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-1 {
}

.menu-item.menu-level-2.menu-count-1 {
}

.menu-item.menu-level-2.menu-count-2 {
}

.menu-item.menu-level-2.menu-count-3 {
}

.menu-item.menu-level-2.menu-count-4 {
}

.menu-item.menu-level-2.menu-count-5 {
}

.menu-item.menu-level-2.menu-count-6 {
}

.menu-item.menu-level-2.menu-count-7 {
}

.menu-item.menu-level-2.menu-count-8 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-2 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-3 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-4 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-2 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-3 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-5 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-6 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-7 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-8 {
}

.menu-item.menu-item--expanded.menu-level-1.menu-count-9 {
}

.menu-item.menu-level-1.menu-count-10 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-4 {
}

.menu-item.menu-level-1.menu-count-5 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-5 {
}

.menu-item.menu-level-1.menu-count-1 {
}

.menu-item.menu-level-1.menu-count-2 {
}

.menu-item.menu-item--expanded.dropdown.menu-item-first.menu-level-0.menu-count-6 {
}

.menu-item.menu-level-1.menu-count-3 {
}

.menu-item.menu-level-1.menu-count-4 {
}

.menu-item.menu-level-1.menu-count-6 {
}

.menu-item.menu-level-1.menu-count-7 {
}

.navbar-nav.nav-setting-user {
}

.nav-item.nav-item-notifications {
}

.nav-link {
}

.icon-back {
}

.clr {
}

.modal-dialog.modal-lg {
}

.modal-content {
}

.modal-body {
}

.container {
}

.close {
}

.close-icon {
}

.col-12 {
}

.search-modal-form {
}

.input-wrap {
}

.small-p {
}

.search-submit {
}

.bg-grey {
}

.page-top-fixed {
}

.container.p-lg-0 {
}

.px-3.pb-2.px-md-0.d-flex.flex-column.flex-md-row.align-items-md-center.justify-content-between.page-btns-top-fixed {
}

.page-title-content {
}

.d-flex.justify-content-between {
}

.page-container {
}

.section.pt-0.mt-1.mt-md-4 {
}

.container.p-0 {
}

.card.p-lg-4 {
}

.card-body {
}

.col-md-12.center {
}

.sub-heading {
}

.col-md-12 {
    text-align: center;
}

.m-3 {
    margin: 20px
}

.col-md-6 {
}

.form-group.has-float-label {
}

.form-control {
}

.form-group.has-float-label.mb-4 {
}

.col-md-12.mt-4 {
}

.footer.footer-inner {
}

.footer-cont {
}

.side-one {
}

.footer-logo {
}

.copyright {
}

.side-two {
}

.list-unstyled {
}