/* ImageUploader.css */
.form-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    
    gap: 20px; /* Space between columns */
  }
  .form-real{
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    justify-items: end;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px){
    .form-real {
      display: block;
    }
  }
  .input-group {
    display: flex;
    flex-direction: column;
    text-align: right; /* Align text to the right */
  }
  
  .input-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .input-group input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    text-align: right; /* Align text in input fields to the right */
  }
  .input-group select  {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    text-align: right; /* Align text in input fields to the right */
  }
  
  button {
    grid-column: span 2; /* Span both columns */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    text-align: center;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    margin-top: 10px;
    grid-column: span 2; /* Span both columns */
  }
  