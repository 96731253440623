/* PersonEditor.css */
.editor-container {
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.editor-container > h3 {
  direction: rtl;
}

/* Ensure the preloader covers the entire screen */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Set background color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
}

/* Centering content inside the preloader */
.preloader-content {
  text-align: center;
}

/* Style for the preloader logo */
.preloader-logo {
  width: 150px; /* Adjust size as needed */
  height: auto;
}

/* Optional: Style for the spinner */
.preloader-spinner {
  width: 40px;
  height: 40px;
  margin-top: 20px;
  border: 4px solid #ccc;
  border-top: 4px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navbar-toggler {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 25px; /* Increase height to allow more space between bars */
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: #fff; /* White color for the bars */
  border-radius: 5px; /* 5px corner radius */
}

/* Optional: Add more spacing between the bars */
.bar + .bar {
  margin-top: 4px; /* Adjust this value for more/less spacing */
}

/* Ensure the bars remain white in all states */
.navbar-toggler:focus .bar,
.navbar-toggler:hover .bar,
.navbar-toggler[aria-expanded="true"] .bar {
  background-color: #fff !important; /* Force white color */
}

.navbar-toggler:focus,
.navbar-toggler:hover,
.navbar-toggler[aria-expanded="true"] {
  background: none; /* Ensure no background color is applied */
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  text-align: right;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group input,
.input-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  text-align: right;
}
.editing-navigation-Links {
  display: flex;
  margin: 15px;
  align-items: baseline;
  justify-content: center;
}
.nav-link {
  margin: 5px;
}
button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}
/* Base styles for the collapsible menu */
.navbar-collapse {
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

/* Styles when the menu is visible */
.navbar-collapse.show {
  opacity: 1;
  max-height: 500px; /* Adjust this value to fit your content */
}

/* Optional: Adjust the transition duration to suit your preference */
.navbar-collapse {
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.show-link {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #41058b;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.columns-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;
}
.editing-wrapper {
  text-align: right;
}
.pre-loader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
  right: 0;
  left: 0;
  z-index: 9999999;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 10px;
}

.success {
  color: green;
  margin-top: 10px;
}

.edit-form {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .columns-wrapper {
    grid-template-columns: 1fr;
  }
}

.select-container {
  position: relative;
  display: inline-block;
  width: 250px;
  font-family: sans-serif;
}

.select-element {
  appearance: none;
  width: 90%;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  direction: rtl;
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #666;
  pointer-events: none;
}

.select-element:hover {
  border-color: #aaa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.select-element:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}
